import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'

import {
  AboutContactContainer,
  BannerContainer
} from '../styles'
import Img from 'gatsby-image'

export default class ContactPage extends React.Component {
  render () {
    return (
      <Layout>
        <BannerContainer>
          <div>
            <h1>{this.props.data.markdownRemark.frontmatter.title}</h1>
          </div>
          {this.props?.data?.background &&
            <Img
              fluid={this.props.data.background.childImageSharp.fluid}
              fadeIn={false}
              loading='eager'
              objectFit='cover'
              objectPosition='50% 50%'
            />}
        </BannerContainer>
        <AboutContactContainer>
          <HTMLContent content={this.props.data.markdownRemark.html} />
        </AboutContactContainer>
      </Layout>
    )
  }
}

export const contactPageQuery = graphql`
    query ContactPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
            }
        }
        background: file(absolutePath: {regex: "/about-bg/"}) {
          ...InfoPageBanner
        }
    }
    
`
